@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: 30px;
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}
.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(32px);

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
  }

  @media (--viewportMedium) {
    margin-right: 32px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(
      max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 32px), 0px)
    );

    &:last-of-type {
      padding-right: 32px;
      width: calc(
        min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px
      ); /* 32px (padding-right above) */
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);
    }
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4);

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 4);
    }
  }
}


/* categories */
.categoryBody {
  margin: 0;
  padding-top: 68px;
  height:90vh;
}

.categoryTitle{
  text-align: center;
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 30px;
  letter-spacing: -0.35px;
  color: black;
}

.categorySubTitle{
  text-align: center;
  font-size: 1.0em;
  font-weight: 300;
  margin-bottom: 60px;
  letter-spacing: -0.35px;
}

.categoryGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  grid-template-rows: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 25px;
  max-width: 1100px;
  margin: 0 auto;
}


.electronics {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 1.0s ease-in-out, opacity 1.0s ease-in-out;
  grid-row: span 2;
  height: 100%;

  transform: translateX(-100%);
  opacity: 0;
}

.electronicsVisible {
  transform: translateX(0);
  opacity: 1;
}

.electronics:hover {
  transform: scale(1.02);
}

.electronics img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  display: block;
  border-radius: 10px;
}


.categoryItem {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

/* Hover effect */
.categoryItem:hover {
  transform: scale(1.02);
}

.categoryItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}

.categoryName {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .categoryBody {
    height: auto;
    padding-top: 20px;
  }

  .categoryGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  .categoryName {
    font-size: 1em;
  }
}

.responsiveImg {
  width: 100%;  
  height: auto; 
  display: block;  
  object-fit: cover;
  border-radius: 10px;
}

.backgroundWhite {
  background-color: white;
}

.storyBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin-top: 30px;
  background-color: #fafafa;
  padding-top: 100px;
  padding-bottom: 100px;
}

.storyContainer {
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
}

.storyButton {
  background-color: #518c4e;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  font-size: 1.3em;
  margin-bottom: 20px;
  letter-spacing: 0.03em;
}

.storyAnimation {
  -webkit-transform: scale(0);  /* Mobile Safari */
  transform: scale(0); 
  opacity: 0;  
  -webkit-transition: -webkit-transform 1.5s ease, opacity 1.5s ease;
  transition: transform 1.5s ease, opacity 1.5s ease;
}

.storyAnimationVisible {
  -webkit-transform: scale(1);  /* Mobile Safari */
  transform: scale(1);  
  opacity: 1;  
}

.storyFadeInAnimation {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.storyFadeInAnimationVisible {
  opacity: 1;
}


@media (max-width: 768px) {
  .storyAnimation, .storyAnimationVisible {
    transform: none !important;
  }
}


.storyText p {
  color: #4A4A4A;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  line-height: 2;
  font-weight: 522;
}


.highlight {
  color: #518c4e;
}

.learnMore {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.circle {
  width: 14px;
  height: 14px;
  background-color: #518c4e;
  border-radius: 50%;
  margin-right: 10px;
}

.learnText {
  font-size: 1.2em;
  font-weight: bold;
  color: #4A4A4A;
}



@media (max-width: 768px) {
  .storyBody{
    height: auto; 
    margin-top: 50px;
    padding: 20px;
  }
  .storyContainer {
    padding: 0 20px;
  }

  .storyButton {
    font-size: 1em;
    padding: 8px 15px;
  }

  .storyText p {
    font-size: 1em;
  }

  .learn-text {
    font-size: 1em;
  }
}
/* General styling */
.mattersBody {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.whyItMattersContainer {
  text-align: center;
  max-width: 1200px;
  padding: 50px 20px;
  margin: 0 auto;
}

.whyItMattersAnimation{
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.whyItMattersHeading {
  background-color: #4CAF50;
  color: white;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 1.5em;
  margin-bottom: 40px;
}

.slideInLeft {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
  transition: transform 1.0s ease-in-out, opacity 1.0s ease-in-out !important;
}

.slideInLeftVisible {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.slideInLeftText {
  transform: translateX(-50%) !important;
  opacity: 0 !important;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out !important;
}

.slideInLeftTextVisible {
  transform: translateX(0) !important;
  opacity: 1 !important;
}


.slideInRight {
  transform: translateX(100%) !important;
  opacity: 0 !important;
  transition: transform 1.0s ease-in-out, opacity 1.0s ease-in-out !important;
}

.slideInRightVisible {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.slideInRightText {
  transform: translateX(50%) !important;
  opacity: 0 !important;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out !important;
}

.slideInRightTextVisible {
  transform: translateX(0) !important;
  opacity: 1 !important;
}



.featuresGrid {
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  justify-content: center;
}

.featureCard {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  width: 100%;
  height: 210px;
  min-height: 200px;

  opacity: 0;
}

.featureCard img {
  width: 85px;
  height: 85px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.featureCard h3 {
  color: #4A4A4A;
  font-size: 1.2em;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
  }

  .whyItMattersHeading {
    font-size: 1.3em;
    padding: 8px 20px;
  }

  .featureCard {
    width: 100%;
  }
}

.startEarningContainer {
  text-align: left;
  margin: 0 auto;
  background-color: #fafafa;
  padding-top: 40px;
}

.startEarningHeading {
  font-family: var(--fontFamily);
  font-size: 2.2em;
  margin-bottom: 50px;
  font-weight: 700;
}

.startEarningColumns {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: stretch;
}

.imageColumn {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items:flex-start;
  max-width: 100%;
}

.imageColumn img {
  width: 85%; 
  height: 95%;
  object-fit: cover;
}


.column {
  flex: 1;
  text-align: left;
  margin-top: 50px;
}

.column h3 {
  color: #4A4A4A;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 15px;
}

.column p {
  color: #666;
  font-size: 1em;
  line-height: 1.6;
}

.step {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 12px;
  margin-bottom: 40px;
}

.stepIcon {
  background-color: #518c4e;
  color: white;
  padding: 0px 7px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.stepTitle {
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 2px;
}

.stepText {
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: 250;

}

.learnMoreLinkText {
  font-size: 1.3em;
  font-weight: bold;
  color: #4A4A4A;
}

.findAndRentContainer {
  text-align: center;
  margin: 0 auto;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 50px;
}

.findAndRentHeading {
  font-family: var(--fontFamily);
  font-size: 2.2em;
  margin-bottom: 50px;
  font-weight: 700;
}

.findAndRentImageColumn {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items:flex-end;
  max-width: 100%;
  margin-right: 100px;
  margin-left: 80px;
}

.findAndRentImageColumn img {
  width: 80%;
  height: 95%;
  object-fit: cover;
}

.findAndRentColumn {
  flex: 1; 
  text-align: left;
  margin-top: 50px;
  margin-left: 80px;
}

.exploreOurFeaturesContainer {
  text-align: center;
  padding: 50px 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.exploreOurFeaturesAnimation {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.exploreOurFeaturesHeading {
  font-size: 2.5em;
  margin-bottom: 80px;
  color: #4A4A4A;
}

.exploreOurFeaturesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 100px; 
}

.exploreOurFeatureCard {
  text-align: left;
}

.exploreOurFeatureCard img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.exploreOurFeatureTitle {
  font-size: 2.0em;
  font-weight: 700;
  margin: 20px 0px;
}

.exploreOurFeatureCard p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.8;
  font-weight: 300;
}

@media (max-width: 768px) {

  .slideInLeft {
    transform: translateY(-100%) !important; /* Slide down from the top on mobile */
    opacity: 0 !important;
    transition: transform 0.8s ease-out, opacity 0.8s ease-out !important;
  }
  
  .slideInLeftVisible {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }

  .slideInRight {
    transform: translateY(100%) !important; /* Slide up from the bottom on mobile */
    opacity: 0 !important;
    transition: transform 0.8s ease-out, opacity 0.8s ease-out !important;
  }
  
  .slideInRightVisible {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
  
  .electronics {
    transform: translateY(-100%) !important;
    opacity: 0 !important;
    transition: transform 1s ease, opacity 1s ease !important;
  }

  .electronicsVisible {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }

  .exploreOurFeaturesGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    gap: 20px;
  }

  .exploreOurFeatureCard {
    text-align: center;
  }

  .exploreOurFeatureTitle {
    font-size: 1.5em; 
  }

  .exploreOurFeatureCard p {
    font-size: 0.8em; 
  }

  .startEarningColumns {
    flex-direction: column;
    align-items: center;
  }

  .startEarningHeading {
    margin-top: 90px;
  }
  
  .column {
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;

  }

  .imageColumn {
    max-height: 300px;
  }

  .findAndRentImageColumn {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .findAndRentColumn{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.fadeInEffect{
  transform: translateX(0); 
  opacity: 1;  
}