.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBox {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  
}

.searchInput {
  border: none;
  padding: 10px;
  flex: 1;
  outline: none;
  font-size: 16px;
  height: 100%;
  border-radius: 5px 0 0 5px;
}

.searchButton {
  background-color: #508D4E;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
  height: 52px; 
}

.faSearchIcon {
  color: white; /* Apply color to the icon */
  font-size: 18px; /* Apply size to the icon */
}


.searchButton .fa-search {
  color: white;
  font-size: 18px;
}

.searchButton:hover {
  background-color: #508D4E;
}


/* Adjustments for mobile devices */
@media (max-width: 768px) {
  .root {
    background-size: 100% auto !important;
    background-position: top center !important;
    height: auto !important;
  }

  .searchButton {
    height: 45px; 
  }
}
